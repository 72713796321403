@use "shared" as *;

.share_modal {
  z-index: 2;
  width: 210px;
  height: 130px;
  background-color: $black-color;
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid $black-color;
  position: relative;
}

.share_modal_before{
  content: "";
    position: absolute;
    top: -9px;
    right: 30px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 11px solid $black-color;
    // @media ((max-width: 850px) and (min-width:650px)) {
    //   left: 44px;
    // }
}

// .share_modal::before{
//   content: "";
//   position: absolute;
//   top: -9px;
//   right: 30px;
//   width: 0;
//   height: 0;
//   border-left: 10px solid transparent;
//   border-right: 10px solid transparent;
//   border-bottom: 11px solid $black-color;
//   // @media ((max-width: 850px) and (min-width:650px)) {
//   //   left: 44px;
//   // }
// }

.share_icons_wrapper {
  height: 50%;
  align-items: center;

  transition: 0.1s;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 25px 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.share_icons_item {
  display: flex;
  //  align-items: center;
  justify-content: center;
}
.share_icon {
  transition: 0.1s;
  opacity: 0.7;
  filter: invert(83%) sepia(6%) saturate(128%) hue-rotate(357deg)
    brightness(81%) contrast(91%);
}

.share_icon:hover {
  opacity: 1;
  filter: invert(99%) sepia(8%) saturate(236%) hue-rotate(351deg)
    brightness(119%) contrast(100%);
}

// .share_icon:not(:hover) {
//   opacity: 0.7;
// }

.copy_link_wrapper {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  @include body-mono-reg;
  text-transform: uppercase;
  color: $gray-color;
  padding-left: 1.5rem;
  transition: 0.1s;
}
.copy_link_icon {
  transition: 0.1s;
  padding-bottom: 5px;
  filter: invert(83%) sepia(6%) saturate(128%) hue-rotate(357deg)
    brightness(81%) contrast(91%);
}

.copy_link_wrapper:hover {
  color: $white-color;
  .copy_link_icon {
    filter: invert(99%) sepia(8%) saturate(236%) hue-rotate(351deg)
      brightness(119%) contrast(100%);
  }
}
.copy_link_wrapper_copyed {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  @include body-mono-reg;
  text-transform: uppercase;
  padding-left: 1rem;
  transition: 0.1s;
  background-color: $white-color;
  .copy_link_icon {
    filter: invert(0%) sepia(94%) saturate(7476%) hue-rotate(62deg)
      brightness(99%) contrast(96%);
  }
  // .copy_link_icon:hover{

  // }
  color: $black-color;
}

.mobile_share_close{
  display: flex;
  width: 100%;
  justify-content: flex-end;
  position: absolute;
  top: -2.4rem;

}

@use "shared" as *;

.button_element-wrapper {
  display: flex;
  flex-direction: column;
  // gap: 11rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 16rem;
  padding-top: 5rem;
}

.button_element-wrapper > h1 {
  @include h1;
  text-align: center;
  width: 80%;
  @media ((max-width: 850px) and (min-width:650px)) {
    width: 100%;
  }
  padding-bottom: 8.4rem;
}

.button_element-about{
  display: flex;
  flex-direction: column;
  gap: 3.8rem;
  width: 32%;
  padding-bottom: 6.2rem;
}
.underline{
  text-decoration: underline;
  cursor: url("../../../../icons/cursors/pointing.svg"), auto;
}
.button_element-about>p{
  text-align: center;
  @include body-mono;
}


.button_element-wrapper > button {
  @include button;
  width: 60rem;
  height: 60rem;
  border-radius: 50%;
  border: 1px solid var(--Black, #000);
  margin-bottom: 20px;
  @media ((max-width: 1920px) and (min-width:1500px)) {
    width: 50rem;
    height: 50rem;
  }
  @media ((max-width: 850px) and (min-width:650px)) {
    width: 51rem;
    height: 51rem;
  }
}
.button_element-wrapper > button:hover {
  color: $white-color;
  background-color: $black-color;
}

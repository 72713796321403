@use "shared" as *;

.mobile_header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 4.1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 3;
  background-color: $white-color;
  border-bottom: 1px solid $gray-color;
  padding: 0 2rem;
}

.menu {
  padding: 0 2rem;
  position: fixed;
  top: 4.1rem;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 7;
  background-color: $white-color;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.6s cubic-bezier(0.33, 1, 0.68, 1),
    opacity 0.4s cubic-bezier(0.33, 1, 0.68, 1);
}

.menu_open {
  transform: scaleY(100%);
}
.menu_wrapper {
  z-index: 4;
  display: grid;
  /* flex-direction: column; */
  height: 100%;
  width: 100%;
  padding-bottom: 1.5rem;
  align-content: space-around;
  @media (max-width: 375px) {
    padding-bottom: 4rem;
    // width: 90%;
  }
}

.menu_options {
  // margin-top: 8rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2.2rem;
}
.menu_options > p {
  font-family: "Moderat";
  font-size: 4.1rem;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: -0.03em;
  text-align: left;
  text-transform: none;
}

.menu_options > a {
  font-family: "Moderat";
  font-size: 4.1rem;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: -0.03em;
  text-align: left;
  text-transform: none;
}
.menu_lins {
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 3rem;
}
.share_button {
  @include button;
  gap: 20px;
  border-radius: 3rem;
  width: 100%;
  justify-self: flex-end;
  border: 1px solid #000000;
}

.share_button:hover {
  color: $white-color;
  background-color: $black-color;
  .share_button-icon {
    filter: invert(100%) sepia(0%) saturate(7456%) hue-rotate(13deg)
      brightness(104%) contrast(98%);
  }
}
// .header-right_parth,
// .header-left_parth {
//   display: flex;
//   align-items: center;
//   justify-content: flex-start;
//   height: 100%;
// }

// .logo-item {
//   padding: 0px 25px;
// }
// .header-item {
//   @include body-mono;
//   display: flex;
//   align-items: center;
//   padding: 0px 25px;
//   cursor: pointer;
//   height: 100%;
//   transition: 0.3s;
//   border-left: 1px solid $gray-color;
// }
// #register {
//   border-right: 1px solid $gray-color;
// }

// .header-item:hover {
//   background-color: $black-color;
//   color: $white-color;
// }

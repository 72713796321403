@use "shared" as *;

// .footer_item{
//     height: 100%;
//     border-right: 1px solid $gray-color;
//     display: flex;
// }
.footer_section {
  padding: 2rem;
  border-top: 2px solid $gray-color;
  display: flex;
  flex-direction: column;
  height: 26rem;
  width: 100%;
  justify-content: space-between;
}

// .footer_item {
//   @include body-mono;
//   display: flex;
//   align-items: center;
//   padding: 0px 25px;
//   cursor: pointer;
//   height: 100%;
//   transition: 0.3s;
//   border-right: 1px solid $gray-color;
// }

// .footer_item:hover {
//   background-color: $black-color;
//   color: $white-color;
// }

.footer_wrapper {
  // padding: 1rem 0 0 10px;
  display: grid;
  grid-template-columns: 1.7fr 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
}

.footer_wrapper-element {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}
.footer_wrapper-element > P {
  cursor: url("../../../icons/cursors/pointing.svg"), auto;
}
.footer_icons_wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  gap: 22px;
}

.footer {
  display: grid;
  grid-template-columns: 1.7fr 1fr 1.3fr 1.7fr;
  height: 10vh;
  padding-bottom: 10px;
  // align-items: center;
  // width: 100%;
  // height: 2.3rem;
  // border-top: 1px solid $gray-color;
  // background: #fff;
  // display: flex;
  // align-items: center;
  // justify-content: flex-start;
}
.footer_item {
  display: flex;
  align-items: flex-end;
  // border: 1px solid $gray-color;
  height: 100%;
  width: 100%;
}

.subscribe_wrapper {
  padding: 66px 30px 100px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.subscribe {
  padding-top: 4%;
  width: 40%;
  // padding-top: 2rem;
  // display: flex;
  // flex-direction: column;
  // gap: 65px;
}
.subscribe > p {
  @include body-mono;
}

.subscribe-form {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 53px;
}
.subscribe-form > h3 {
  @include h3;
}

.email_input-wrapper {
  border-radius: 20px;
  border: 1px solid $black-color;
  padding: 12px 18px 10px 20px;
  width: 96%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.email_input-wrapper > input {
  border: none;
  @include body-mono-reg;
  width: 50%;
}

.email_input-wrapper > input:active,
:hover,
:focus {
  outline: 0;
  outline-offset: 0;
}

.subscribe_button {
  cursor: url("../../../icons/cursors/pointing.svg"), auto;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* width: 30%; */
  gap: 20px;
}

.footet_item-smallprint {
  @include hint;
  color: $dark-brown;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: url("../../../icons/cursors/pointing.svg"), auto;
}

.footet_item-smallprint > a {
  text-decoration: underline !important;
}

.footer-element-share {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  cursor: url("../../../icons/cursors/pointing.svg"), auto;
  position: relative;
}

.footer-share-icon {
  height: 1.6rem;
  margin-bottom: 6px;
}

.share_modal_footer {
  transition: 0.3s;
  position: absolute;
  top: 100%;
  padding-top: 0.8rem;
  right: -32px;
  

}

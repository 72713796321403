@use "shared" as *;

.feature-wrapper {
  display: grid;
  grid-template-columns: repeat(1, 1fr) auto;
  grid-template-rows: auto;
  align-items: center;

  gap: 8vw;
  background-color: $white-color;
  // height: 100vh;
  padding: 0rem 2rem 3rem;
  margin-top: 5rem;
  // padding: 4rem 2rem 3rem;
  @media (min-width: 1920px) {
    margin-top: 6.2rem;
  }

  @media ((max-width: 850px) and (min-width:650px)) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5rem 2rem 0 2rem;
    gap: 11vw;
    margin-top: 0rem;
  }
}

.feature-animation {
  // flex: 0.8;
  background-color: $gray-color;
  width: 43vw;
  height: 89vh;
  border-radius: 20px;
  border: 1px solid $gray-color;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ((max-width: 1500px) and (min-width:1000px)) {
    width: 45vw;
  }
  @media ((max-width: 850px) and (min-width:650px)) {
    width: 100%;
    height: 41vh;
  }
}

.feature-animation > p {
  color: var(--Black, #000);
  text-align: center;
  font-family: "Simplon Mono";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 122%; /* 15.86px */
  letter-spacing: -0.26px;
  text-transform: uppercase;
}

@keyframes slideIn {
  0% {
    transform: translateY(-10%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@mixin feature-info-animation {
  animation: slideIn 0.5s ease-in-out;
  transition: var(--main-transition);
  --main-transition: transform 0.5s cubic-bezier(0.33, 1, 0.68, 1),
    opacity 0.3s cubic-bezier(0.33, 1, 0.68, 1);
}
.feature-info {
  // animation: slideIn 1s ease-in-out;
  // position: sticky;
  // top: 50px;
  // padding-top: 6%;
  // width: 94%;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  gap: 90px;
  // transition: var(--main-transition);
  // --main-transition: transform 0.8s cubic-bezier(0.33,1,0.68,1),opacity 0.4s cubic-bezier(0.33,1,0.68,1);
}

.feature-info_name {
  @include body-mono;
  @include feature-info-animation;
}

.feature-info_descr {
  // @include feature-info-animation;
  width: 66%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.feature-info_descr > h4 {
  @include h4;
  @include feature-info-animation;
}

.feature-info_descr > p {
  @include body-mono;
  @include feature-info-animation;
}

.feature-info_list {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
  @include feature-info-animation;
}

.feature-info_options {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 40px;
  @include feature-info-animation;
}
.feature-info_buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  // @include feature-info-animation;
}

.feature-info_buttons > button {
  @include button;
  gap: 20px;
  border: 1px solid $black-color;
  padding: 12px 18px 10px 20px;
}

.feature-info_buttons > button:hover {
  color: $white-color;
  background-color: $black-color;
  .feature-info_button-icon {
    filter: invert(100%) sepia(0%) saturate(7456%) hue-rotate(13deg)
      brightness(104%) contrast(98%);
  }
}

.feature-info_list-item {
  display: flex;
  flex-direction: row;
  gap: 10px;
  @include feature-info-animation;
}

.feature-info_list-item > p {
  @include body-mono;
}
// .feature-info_list-point{
//   margin-right: 20px;
// }

// .feature-info_wrapper{
//   // flex: 1;
// }

@use "shared" as *;

.input {
  @include body-mono;
  height: 95%;
  position: absolute;
  height: 722px;
  transition: 0.5s;
  @media (min-width: 1900px) {
    height: 100%;
    padding-top: 4rem;
  }
  @media (min-width: 2500px) {
    padding-top: 10rem;
  }
  // top: 5%;
  // left: 14%;
}

.undefined {
  position: absolute;
  // top: 0%;
  // left: 0%;
}
.dashboard {
  @include body-mono;
  position: absolute;
  width: 40%;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  @media (min-width: 2500px) {
    width: 39%;
    padding-left: 3rem;
  }
  // top: 42%;
  // left: 27.5%;
}
.output {
  @include body-mono;
  position: absolute;

  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 2500px) {
    padding-top: 10rem;
  }
  @media ((min-width: 1200px) and (max-width:1500px)) {
    padding-top: 10rem;
  }
  //
  // top: 12%;
  // left: 74%;
}

.figure_input {
  position: relative;
  height: 84%;
  border: 1px solid var(--Gray, #aaa8a5);
  width: 120px;
  @media (min-width: 2500px) {
    width: 168px;
  }
}
.figure_input > p {
  @include body-mono;
  position: absolute;
  top: -18px;
  left: -44px;
}

.figure_dashboard {
  height: 100%;
  border: 1px solid var(--Gray, #aaa8a5);
  height: 120px;
  position: relative;
}

.figure_dashboard > p {
  @include body-mono;
  position: absolute;
  bottom: 0;
  left: -76px;
}

.figure_output {
  @include body-mono;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40rem;
  height: 40rem;
  border-radius: 50%;
  border: 1px solid var(--Gray, #aaa8a5);
  @media (min-width: 2500px) {
    width: 59rem;
    height: 59rem;
  }

  @media ((min-width: 1500px) and (max-width:1920px)) {
    width: 36rem;
  height: 36rem;
  }
  @media ((min-width: 1200px) and (max-width:1500px)) {
    width: 32rem;
    height: 32rem;
  }
}

@use "shared" as *;

body {
  @include body-mono;
  margin: 0;
}

// html {
//   scroll-behavior: smooth;
//   transition: scroll-behavior 1s ease-in-out;
// }

:root {
  height: 100%;
  width: 100%;
  font-size: 10px;
}
// @media ((max-width: 2560px) and (min-width:1919px)) {
//   :root {
//     font-size: 13.3px;
//   }
// }

@media ((max-width: 320px)) {
  :root {
    font-size: 7px;
  }
}

* {
  box-sizing: border-box;
  // -webkit-font-smoothing: antialiased;
}
// ::selection {
//   background-color: #AAA8A5;
// }
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
  word-wrap: break-word;
}

@font-face {
  font-family: "Moderat";
  src: url("./fonts/Moderat-Trial-Medium.woff2") format("woff2");
  font-weight: 500;
}

@font-face {
  font-family: "Simplon Mono";
  src: url("./fonts/SimplonMono-Regular-WebTrial.woff2") format("woff2");
  font-weight: 400;
}

@use "shared" as *;



.select {
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $gray-color;

  &:focus {
    border-color: $black-color;
  }
}

.select__placeholder {
  @include body-mono-reg;
  color: $gray-color;
  padding-left: 10px;
  font-size: 14px;

  &_selected {
    color: $black-color;
  }
}

.select__button {
  height: 40px;
  width: 40px;
  flex-shrink: 0;
  background-image: url("./icons/button.svg");
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.select:focus .select__button {
  transform: rotate(180deg);
}

.select__items {
  position: absolute;
  top: 39.5px;
  left: -0.5px;
  width: calc(100% + 1px);
  display: none;
  flex-direction: column;
  border-bottom: 1px solid $gray-color;
  border-left: 1px solid $gray-color;
  border-right: 1px solid $gray-color;
  background-color: $white-color;
  padding: 7.5px 0px;
}

.select__item {
  @include body-mono-reg;
  height: 25px;
  display: flex;
  align-items: center;
  padding: 0px 9px;
  cursor: pointer;

  &:hover {
    background-color: $light-gray-color;
  }
}

.select:focus .select__items {
  display: flex;
}

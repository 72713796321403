@use "shared" as *;

.auth-modal {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 381px;
    padding: 50px 40px 40px;
    background-color: #fff;
}

.auth-modal__close-button {
    position: absolute;
    top: 20px;
    right: 24px;
    width: 20px;
    height: 20px;
    background: url('../../../../icons/close.svg') no-repeat center;
    // background-image: url('./static/close.svg');
    cursor: pointer;
}

.auth-modal__button {
    margin-top: 30px;
    align-self: flex-end;
    @include button;
    border: 1px solid $black-color;
    transition: 0.3s;
}
.auth-modal__button:hover{
    background-color: $black-color;
    color: $white-color;
}
.auth-modal__button:disabled{
    opacity: 0.2;
}
@use "shared" as *;

.main-wrapper {
  background-color: $white-color;
  display: flex;
  flex-direction: column;
  position: relative;
}

// .auth_wrap{
//   position: absolute;
//   height: 100vh;
//   width: 100vw;
//   overflow: hidden;
//   pointer-events: none;
// }

// .auth{
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   z-index: 5;
//   background-color: rgba(0, 0, 0, 0.5);
//   pointer-events: none;
// }

.main-wrapper_modal_open{
  background-color: $white-color;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh;
  overflow: hidden;
  pointer-events: none;
}

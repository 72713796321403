@use "shared" as *;

.cookie_notification {
  z-index: 4;
  position: fixed;
  bottom: 0.6rem;
  right: 0.6rem;
  width: 46.6rem;
  //   height: 71px;
  background-color: $black-color;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.6rem;
  @media (max-width: 650px) {
    width: 100%;
    bottom: 0;
    right: 0;
    flex-direction: column;
    gap: 1.7rem;
  }
}
.cookie_notification-info {
  width: 50%;
  // color: $white-color;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  color: $white-color;
  @media (max-width: 650px) {
    width: 100%;
  }
}
.show_details {
  color: $gray-color;
  cursor: url("../../../../icons/cursors/pointing.svg"), auto;
}

.cookie_notification-buttons {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @media (max-width: 650px) {
    width: 100%;
  }
}
.cookie_notification-buttons > button {
  width: 50%;
  @include button;
  color: $white-color;
  background-color: $black-color;
  border: none;
  padding: 12px 20px 10px 20px;
  align-items: center;
  cursor: url("../../../../icons/cursors/pointing.svg"), auto;
  @media (max-width: 650px) {
    padding: 20px 55px;
  }
}

.accept-button {
  border: 1px solid $white-color !important;
}

@use "shared" as *;

.swiper {
  width: 100%;
  //   height: 100%;
  padding-top: 5.5rem;

}

.swiper-slide {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 83vw;
  
  height: 40vh;

//   height: 325px;
  border-radius: 20px;
  border: 1px solid $gray-color;
  @media (max-width: 320px) {
    height: 43vh;
  }
}

//  .swiper-wrapper {
//     transform: translate3d(0px, 0, 0)!important;
// }

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mobile-feature-info_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4rem 2rem;
  gap: 2rem;
}
.mobile-feature-info {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  gap: 2rem;
  transition: 0.3s;
}

.mobile-feature-info_name {
  @include body-mono;
}
.mobile-feature-info_descr {
  // width: 66%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.mobile-feature-info_descr > h4 {
  @include h4;
}

.mobile-feature-info_descr > p {
  @include body-mono;
}

.mobile-feature-info_list {
  //   width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
}
.mobile-feature-info_options {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4rem;
}
.mobile-feature-info_list-item {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.mobile-feature-info_list-item > p {
  @include body-mono;
}
.mobile-feature-info_options > button {
  @include button;
  width: 100%;
  gap: 20px;
  border: 1px solid #000000;
  // padding: 20px 18px 20px 20px;
  border-radius: 30px;
  @media (max-width: 320px) {
    padding: 9px 18px;
  }
  // background-color: red;
}

.mobile-feature-info_options > button:hover {
  color: $white-color;
  background-color: $black-color;
  .mobile-feature-info_button-icon {
    filter: invert(100%) sepia(0%) saturate(7456%) hue-rotate(13deg)
      brightness(104%) contrast(98%);
  }
}
// .swiper-slide {
//   width: 60%;
// }

// .swiper-slide:nth-child(2n) {
//   width: 40%;
// }

// .swiper-slide:nth-child(3n) {
//   width: 20%;
// }

@use 'shared' as *;

.radio {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.radio-button {
    display: flex;
    align-items: center;
}

.radio-button__input {
    width: 16px;
    height: 16px;
    cursor: pointer;
    background-image: url('./icons/radio.svg');
    background-repeat: no-repeat;
    background-size: contain;

    &_selected {
        background-image: url('./icons/radio_selected.svg');
    }
}

.radio-button__label {
    margin-left: 10px;
    color: $black-color;
}
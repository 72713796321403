@use "shared" as *;

.wrapper {
  background-color: $white-color;
  display: flex;
  flex-direction: column;
}

.document_wrapper {
  display: flex;
  width: 100%;
  padding: 10rem 0 16rem;
  justify-content: center;
}
.document_info {
  display: flex;
  flex-direction: column;
  width: 32%;
  gap: 7.2rem;
  @media ((min-width: 650px) and (max-width: 850px)){
    width: 75%;
  }
  @media (max-width: 650px){
    width: 100%;
    padding: 0 2rem;
  }
  // background-color: red;
  // height: 10vh;
}

.document_info-title_section {
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
}
.document_info-title {
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
}

.document_info-title > h3 {
  @include h3;
}
.document_info-title > p {
  @include body-mono-reg;
}

.document_info-title_section > p {
  @include body-mono-reg;
}

.document_info-section {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}
.document_info-section > h4 {
  @include h4;
}
.document_info-section_info{
    display: flex;
    flex-direction: column;
    gap: 3.2rem ;
}

.document_info-section_info>p{
    @include body-mono-reg;                
}
.bold{
    @include body-mono-reg;   
    font-weight: bold;
    font-style: italic;
}
.info_list-item{
    display: flex;
    flex-direction: row;
    gap: 1.2rem;
}
.info_list-item>p{
    @include body-mono-reg;   
}

.list_marker{
    // height: 20px;
    width: 20px;
}
.highlighted{
    background: rgba(144, 138, 130, 0.50);
}


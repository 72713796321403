@use "shared" as *;

.section_text {
  padding: 66px 30px 100px 30px;
  @media ((max-width: 850px) and (min-width:650px)) {
    padding: 50px 30px 100px 30px;
  }
  @media ((max-width: 650px)) {
    padding: 0;
    // height: 100vh;
  }
}

.text {
  //   @include h2;
  //   padding: 150px 30px 100px 30px;
  display: flex;
  flex-direction: row;
  gap: 78px;
  overflow: hidden;
  @media ((max-width: 850px) and (min-width:650px)) {
    display: flex;
    flex-direction: column-reverse;
    gap: 50px;
  }
  @media ((max-width: 650px)) {
    display: flex;
    flex-direction: column-reverse;
    gap: 6rem;
    padding: 7rem 2rem;
  }

  //   font-size: 8rem;
}
.list {
  width: 20%;
  padding-top: 4.3rem;
  display: flex;
  flex-direction: column;
  gap: 65px;
  @media (min-width: 2000px) {
    padding-top: 6.5rem;
  }
  @media ((max-width: 850px) and (min-width:650px)) {
    display: flex;
    flex-direction: row;
    gap: 50px;
    width: 100%;
  }
  @media (max-width: 650px) {
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: 3.6rem;
    width: 100%;
  }
}

.trans-text {
  width: 75%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  column-gap: 1.7rem;
  // gap: 0rem;
  flex-wrap: wrap;
  @media ((max-width: 850px) and (min-width:650px)) {
    width: 100%;
  }
  @media ((max-width: 650px)) {
    width: 100%;
    column-gap: 1.1rem;
    // padding:0 2rem;
  }
}
.trans-text-line {
  display: flex;
  align-items: flex-start;
  column-gap: 1.7rem;
  // @media ((max-width: 1048px) and (min-width:910px)) {
  //   column-gap: 1.7rem;
  // }
  // @media ((max-width: 910px) and (min-width:834px)) {
  //   column-gap: 1.7rem;
  // }
  // row-gap: 1rem
}

.trans-text-item {
  @include h2;
  // font-size: 6.2rem;
  transition: opacity 0.3s;
  @media (min-width: 2000px) {
    font-size: 13.2rem;
  }
  // @media ((max-width: 2000px) and (min-width:px)) {
  //   font-size: 8.6rem;
  // }
  @media ((max-width: 1800px) and (min-width:1500px)) {
    font-size: 8.6rem;
  }
  @media ((max-width: 1500px) and (min-width:1330px)) {
    font-size: 7.6rem;
  }
  @media ((max-width: 1330px) and (min-width:1188px)) {
    font-size: 6.6rem;
  }
  @media ((max-width: 1188px) and (min-width:1048px)) {
    font-size: 6rem;
  }
  @media ((max-width: 1048px) and (min-width:910px)) {
    font-size: 5rem;
  }
  @media ((max-width: 910px) and (min-width:834px)) {
    font-size: 4.5rem;
  }
  @media ((max-width: 850px) and (min-width:650px)) {
    font-size: 7.6rem;
  }
  @media ((max-width: 650px)) {
    font-size: 4.1rem;
  }
}
.trans-text > p {
  @include h2;
  // font-size: 5rem;
}

.text_list-item {
  display: grid;
  grid-template-columns: 10px 1fr;
  flex-direction: row;
  gap: 10px;
}

.text_list-item > p {
  @include body-mono;
}

.list_text {
  @include body-mono;
}
// .point{
//   height: 10px;
//   width: 10px;
// }

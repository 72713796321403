@use "shared" as *;

.auth__container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
 
    inset: 0;
    z-index: 10;
    background-color: #00000080;

       // width: 100vw;
    // height: 100vh;
}
@use "shared" as *;

.tablet_preview {
  margin: 4rem 0 0rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6vh;
  justify-content: flex-start;
  position: relative;
  @media ((max-width: 850px) and (min-width:650px)) {
    height: 100vh;
    justify-content: center;
  }
  // background-color: red;
}

.tablet_preview-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tablet_preview-text {
  width: 50%;
  @include body-mono;
  text-align: center;
  padding-top: 6vh;
  @media (min-width: 1920px) {
    padding-top: 0;
  }
  @media ((max-width: 850px) and (min-width:650px)) {
    padding-top: 0;
    width: 70%;
  }
}
.tablet_preview-text-wrapper > h1 {
  z-index: 4;
  @include h1;
  z-index: 1;
  text-align: center;
  line-height: 100%;
  letter-spacing: -3px;

  .highlight {
    z-index: 1;
    position: relative;
    // cursor: url("../../../../../icons/cursors/pointing.svg"), auto;
    transition: 0.3s;
  }
  .highlight {
    letter-spacing: -2.3px;
  }
  .highlight::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 6px;
    width: 100%;
    border-bottom: 7px solid $black-color;
  }
}

.tablet_preview > h4 {
  @include h4;
  text-align: center;
  @media ((max-width: 850px) and (min-width:650px)) {
    font-size: 2.8rem;
  }
}

.tablet_preview-buttons-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.tablet_preview-buttons-wrapper > button {
  @include button;
  border: 1px solid $black-color;
  gap: 20px;
}

.tablet_preview-buttons-wrapper > button:hover {
  background-color: $black-color;
  color: $white-color;
  .preview-button-icon {
    filter: invert(100%) sepia(0%) saturate(7456%) hue-rotate(13deg)
      brightness(104%) contrast(98%);
  }
}

$black-color: #000000;
$white-color: #fff;
$gray-color: #aaa8a5;
$dark-brown: #5a5959;
$brown : #908A82;
$error: #F62F53;
$light-gray-color: #efefef;

@mixin h1 {
  font-family: "Moderat";
  text-transform: none;
  font-weight: 700;
  font-size: 15rem;
  line-height: 95%;
  letter-spacing: -4.5px;
  color: $black-color;
  @media (min-width: 2000px) {
    font-size: 19rem;
  }
  @media ((max-width: 1800px) and (min-width:1500px)) {
    font-size: 12.5rem;
  }
  @media ((max-width: 1500px) and (min-width:1110px)) {
    font-size: 13.3rem;
  }
  @media ((max-width: 1110px) and (min-width:900px)) {
    font-size: 11.6rem;
  }
  @media ((max-width: 1000px) and (min-width:850px)) {
    font-size: 10.6rem;
  }
  @media ((max-width: 850px) and (min-width:650px)) {
    font-size: 10.4rem;
  }
}

@mixin h2 {
  font-family: "Moderat";
  text-transform: none;
  font-style: normal;
  font-weight: 400;
  font-size: 10.6rem;
  line-height: 107%;
  letter-spacing: -3.18px;
  color: $black-color;
}

@mixin h3 {
  font-family: "Moderat";
  text-transform: none;
  font-weight: 400;
  font-size: 6rem;
  line-height: 100%;
  letter-spacing: -3px;
  color: $black-color;
  font-style: normal;
}

@mixin h4 {
  font-family: "Moderat";
  text-transform: none;
  font-weight: 400;
  font-size: 3.2rem;
  line-height: 122%;
  letter-spacing: -1.28px;
  color: $black-color;
  font-style: normal;
}
@mixin h5 {
  font-family: "Moderat";
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 116%;
  letter-spacing: -0.44px;
  color: $black-color;
}

@mixin body-m {
  font-family: "Moderat";
  text-transform: none;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 116%;
  letter-spacing: -0.32px;
  color: $black-color;
}

@mixin body-mono {
  font-family: "Simplon Mono";
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 122%;
  letter-spacing: -0.26px;
  color: $black-color;
}

@mixin body-mono-reg {
  font-family: "Simplon Mono";
  text-transform: none;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 122%;
  letter-spacing: 0px;
  color: $black-color;
}

@mixin button {
  font-family: "Simplon Mono";
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 108%;
  letter-spacing: -0.64px;
  color: $black-color;
  padding: 12px 18px 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white-color;
  color: $black-color;
  transition: 0.3s;
}

@mixin table {
  font-family: "Simplon Mono";
  text-transform: none;
  font-weight: 400;
  font-size: 1.3rem;
  letter-spacing: -0.65px;
  color: $black-color;
  line-height: normal;
  font-style: normal;
}

@mixin tools {
  font-family: "Simplon Mono";
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 106%;
  letter-spacing: -0.13px;
  color: $black-color;
  font-style: normal;
}

@mixin caption {
  font-family: "Simplon Mono";
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 106%;
  letter-spacing: 0px;
  color: $black-color;
}

@mixin hint {
  font-family: "Simplon Mono";
  text-transform: none;
  font-weight: 400;
  font-size: 1rem;
  line-height: 122%;
  letter-spacing: 0px;
  color: $black-color;
}

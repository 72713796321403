@use "shared" as *;

.mobile_button_element_section {
  // height: 78vh;
  padding: 5.5rem 2rem 6.1rem;
}

.mobile_button_element-wrapper {
  display: flex;
  flex-direction: column;
  gap: 6rem;
  align-items: center;
  justify-content: center;
  // margin-bottom: 11rem;
}

.mobile_button_element-wrapper > h1 {
  @include h1;
  font-size: 6rem;
  letter-spacing: -2.1px;
  // width: 80%;
}

.mobile_button_element-wrapper > button {
  @include button;
  gap: 1.2rem;
  align-items: center;
  justify-content: center;
  width: 33.5rem;
  height: 33.5rem;
  border-radius: 50%;
  border: 1px solid var(--Black, #000);
  // margin-bottom: 20px;
}
.mobile_button_element-wrapper > button:hover {
  color: $white-color;
  background-color: $black-color;
  .mobile_button_element-icon {
    filter: invert(100%) sepia(0%) saturate(7456%) hue-rotate(13deg)
      brightness(104%) contrast(98%);
  }
}

@use "shared" as *;

.preview_section {
  overflow: hidden;
}

.preview {
  // height: 92vh;
  margin: 4rem 0 0rem 0;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  // gap: 6rem;
  gap: 6vh;
  justify-content: flex-start;
  position: relative;
  // @media ((max-width: 1920px) and (min-width:1500px)) {
  //   gap: 3rem;
  // }
  @media ((max-width: 1500px) and (min-width:1000px)) {
    gap: 7vh;
  }

  @media (min-width: 1920px) {
    justify-content: center;
    height: 100vh;
  }
  // overflow: hidden;
  // margin: 4rem 0 0rem 0;
  // width: 100%;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // gap: 6rem;
  // // justify-content: space-between;
  // position: relative;
  // overflow: hidden;

  // @media ((max-width:2560px) and (min-width:1920px)) {
  //   margin: 4rem 0 0rem 0;
  //   width: 100%;
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   gap: 6rem;
  //   // justify-content: space-between;
  //   position: relative;
  //   overflow: hidden;
  // }
}

.preview-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.preview-text {
  width: 50%;
  @include body-mono;
  text-align: center;
  padding-top: 6vh;
  // padding-top: 6rem;
  // @media ((max-width: 1920px) and (min-width:1500px)) {
  //   padding-top: 3rem;
  // }
  @media (min-width: 1920px) {
    padding-top: 0;
  }
}
.preview-text-wrapper > h1 {
  z-index: 4;
  @include h1;
  z-index: 1;
  text-align: center;
  line-height: 100%;
  letter-spacing:-3px;
  // margin-bottom: 7.25rem;
  // font-size: 8rem;
  .highlight {
    z-index: 1;
    position: relative;
    cursor: url("../../../../icons/cursors/pointing.svg"), auto;
    transition: 0.3s;
  }
  .highlight{
    letter-spacing: -2.3px;
  }
  .highlight::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 6px;
    width: 100%;
    border-bottom: 7px solid $black-color;
  }
  .highlight:hover {
    .highlightBlock {
      opacity: 1;
    }
  }
 
}

.highlightBlock {
  z-index: -1;
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 100%;
  height: 74.5%;
  background: $brown;
  opacity: 0;
  transition: opacity 0.3s;
}
.highlightBlock:hover {
  opacity: 1;
}

.preview > h4 {
  @include h4;
  text-align: center;
  @media ((max-width: 1800px) and (min-width:1500px)) {
    font-size: 2.5rem;
  }
  @media ((max-width: 1500px) and (min-width:1000px)) {
    font-size: 2.8rem;
  }
  
  @media ((max-width: 1000px) and (min-width:850px)) {
    font-size: 2.5rem;
  }
  // margin-bottom: 7rem;
}

.preview-buttons-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.preview-buttons-wrapper > button {
  @include button;
  border: 1px solid $black-color;
  gap: 20px;
}

.preview-buttons-wrapper > button:hover {
  background-color: $black-color;
  color: $white-color;
  .preview-button-icon {
    filter: invert(100%) sepia(0%) saturate(7456%) hue-rotate(13deg)
      brightness(104%) contrast(98%);
  }
}

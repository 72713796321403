@use "shared" as *;

.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 4.1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 3;
  background-color: $white-color;
  border-bottom: 1px solid $gray-color;
}
.header-right_parth,
.header-left_parth {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

.logo-item {
  padding: 0px 25px;
}
.header-item {
  position: relative;
  @include body-mono;
  display: flex;
  align-items: center;

  padding: 0px 25px;
  cursor: url("../../../icons/cursors/pointing.svg"), auto;
  height: 100%;
  transition: 0.3s;
  border-left: 1px solid $gray-color;
}
#register {
  border-right: 1px solid $gray-color;
}

// .header-item:hover {
//   background-color: $black-color;
//   color: $white-color;
//   .header-item-icon {
//     filter: invert(100%) sepia(0%) saturate(7456%) hue-rotate(13deg)
//       brightness(104%) contrast(98%);
//   }
// }

.header-item-icon {
  // width: 1rem;
  height: 1.6rem;
  margin-bottom: 6px;
}

.header-item#share {
  gap: 5px;
  // align-items: flex-end;
}
.share_modal_header{
  transition: 0.3s;
  position: absolute;
  top: 100%;
  padding-top: 0.8rem;
  right: -3%;
}

@use "shared" as *;

.tablet_footer_section {
  height: 35rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  // padding: 2rem 0 0 10px;
  border-top: 1px solid $gray-color;
  // display: flex;
  // flex-direction: column;
  // height: 45rem;
  // width: 100%;
  // justify-content: space-between;
}
.tablet_footer_item {
  border-top: 1px solid $gray-color;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  height: 5rem;
  width: 100%;
}
.tablet_footer_cell {
  // background-color: red;
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tablet_footer_item#copyright {
  justify-content: center;
}

// .footer_item {
//   @include body-mono;
//   display: flex;
//   align-items: center;
//   padding: 0px 25px;
//   cursor: pointer;
//   height: 100%;
//   transition: 0.3s;
//   border-right: 1px solid $gray-color;
// }

// .footer_item:hover {
//   background-color: $black-color;
//   color: $white-color;
// }


.tablet_footer_wrapper {
  display: grid;
  // grid-template-columns: 1.7fr 1fr 1.3fr 1.7fr;
  // height: 10vh;
  // padding-bottom: 10px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr; 
  gap: 6rem;
  // align-items: center;
  // width: 100%;
  // height: 2.3rem;
  // border-top: 1px solid $gray-color;
  // background: #fff;
  // display: flex;
  // align-items: center;
  // justify-content: flex-start;
}
.tablet_footer_second-row {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}
.tablet_footer_wrapper-element {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}
.tablet_footer-element-share{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  position: relative;
}

.share_modal_tablet_footer{
  transition: 0.3s;
  position: absolute;
  top: 100%;
  padding-top: 0.8rem;
  left: 0;
}

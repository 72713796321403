@use "shared" as *;

// .footer_item{
//     height: 100%;
//     border-right: 1px solid $gray-color;
//     display: flex;
// }
.mobile_footer_section {
  // height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 52px;
  padding: 2rem;
  border-top: 1px solid $gray-color;
  height: 90vh;
}
.mobile_footer_info {
  display: flex;
  flex-direction: column;
  gap: 13.6rem;
}
.mobile_footer_item {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;

  // border-top: 1px solid $gray-color;
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  // justify-content: space-between;
  // padding: 0 1rem;
  // height: 5rem;
  // width: 100%;
}
.mobile_footer_cell {
  // background-color: red;
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  align-items: flex-start;
  position: relative;
  // justify-content: c;
}
.mobile_footer_cell-links {
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  // align-items: flex-end;
  justify-content: flex-start;
}
.mobile_footer_cell_icon {
  height: 33px;
  width: 33px;
}

// .tablet_footer_item#copyright {
//   justify-content: center;
// }

// .footer_item {
//   @include body-mono;
//   display: flex;
//   align-items: center;
//   padding: 0px 25px;
//   cursor: pointer;
//   height: 100%;
//   transition: 0.3s;
//   border-right: 1px solid $gray-color;
// }

// .footer_item:hover {
//   background-color: $black-color;
//   color: $white-color;
// }

.mobile_footer_form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.mobile_subscribe {
  width: 60%;
}
// .subscribe > p {
//   @include body-mono;
// }

// .mobile_subscribe-form {
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   justify-content: flex-start;
//   gap: 53px;
// }
// .subscribe-form > h3 {
//   @include h3;
// }

.mobile_email_input-wrapper {
  border-radius: 58px;
  border: 1px solid $black-color;
  padding: 20px 18px 20px 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.mobile_email_input-wrapper > input {
  border: none;
  @include body-mono-reg;
  width: 85%;
}

.mobile_email_input-wrapper > input:active,
:hover,
:focus {
  outline: 0;
  outline-offset: 0;
}

.subscribe_button {
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* width: 30%; */
  gap: 20px;
}

.mobile_smallprint {
  @include hint;
  color: $dark-brown;
  width: 60%;
  font-size: 1.3rem;
}
.mobile_smallprint > a {
  text-decoration: underline;
  cursor: pointer;
  color: inherit;
}

.mobile_footer-element-share {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  position: relative;
}

.share_modal_mobile_footer {
  width: 100%;
  transition: 0.3s;
  position: absolute;
  // top: 100%;
  top: 0;
  // padding-top: 0.8rem;
  left: 0
}

.share_modal_mobile_footer_close{
  display: flex;
  width: 100%;
  justify-content: flex-end;
  position: absolute;
  top: -2.4rem;
}

@use "shared" as *;

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.email__modal_wrapper {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.email__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.email__form_item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}
.email__title {
  @include h5;
  text-transform: none;
}

// .email__about {
//     margin-top: 16px;
// }

.email__name-label {
  @include body-mono;
  // margin-top: 30px;
}

.email__name {
  // margin-top: 10px;
  min-height: 40px;
  border: 1px solid #c4c2bf;
  width: 100%;
  padding: 12px 10px;
  resize: none;
  outline: none;
  @include table;
}
.email__name:focus {
  border: 1px solid $black-color;
}

.email__email-label {
  @include body-mono;
  // margin-top: 20px;
}

.email__email {
  // margin-top: 10px;
  min-height: 44px;
  border: 1px solid #c4c2bf;
  width: 100%;
  padding: 12px 10px;
  resize: none;
  outline: none;
  @include table;
}
.email__email:focus {
  border: 1px solid $black-color;
}

.email__or {
  @include body-mono;
  // margin-top: 30px;
}

.email__gmail {
  // margin-top: 30px;
  padding-left: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  border: 1px solid #e1e1e1;
  background-image: url("../../../../icons/GoogleLogo.svg");
  // background-image: url('./icons/google.svg');
  background-repeat: no-repeat;
  background-position: 15px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
  text-transform: none;
  color: #0000008a;
  cursor: url("../../../../icons/cursors/pointing.svg"), auto;
}

.email__subscribe-approve {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.7rem;
}
.email__subscribe-approve > p {
  @include body-mono-reg;
}

input[type="checkbox"] {
  appearance: none; /* Убираем стандартный стиль */
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 25px !important;
  height: 16px !important;
  border: 1px solid $gray-color;
  background-color: white;
  outline: none;
  cursor: url("../../../../icons/cursors/pointing.svg"), auto;
  content: "";
  // color:  white;
}

//   input[type="checkbox"]:checked {
//     background-color: black;
//   }

input[type="checkbox"]:checked {
  // content: '\2713';
  content: url("../../../../icons/checkMark.svg");
  display: block;
  text-align: center;
  width: 16px;
  height: 16px;
  // color: white/
  // font-size: 14px;
  // line-height: 20px;
}

.email__rules {
  @include hint;
  color: $dark-brown;
}
.email__rules > a {
  @include body-mono-reg;
  font-size: 1rem;
  text-decoration: underline;
  color: $dark-brown;
  cursor: url("../../../../icons/cursors/pointing.svg"), auto;
}

.email__invalid-notification {
  position: absolute;
  bottom: -18px;
}
.email__invalid-notification > p {
  @include hint;
  color: $error;
}

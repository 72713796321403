@use "shared" as *;

.features {
  position: relative;
  height: 300vh;
  z-index: 1;
}

.features_item {
  width: 100%;
  position: sticky;
  top: 10px;
  // animation: slideUp 0.7s forwards; 
}

// @keyframes slideUp {
//   0% {
//     top: 50px;
//   }
//   100% {
//     top: 10px;
//   }
// }


// .container {
//   min-height: 330vh;
//   width: 100%;
//   display: grid;
//   gap: 30px;
//   // grid-template-columns: repeat(3, 1fr);
//   padding-left: 30px;
//   padding-right: 30px;
// }

// .sticky {
//   width: 100%;
//   background-color: #bfbfbf;
//   position: sticky;
//   top: 10px;
// }

// * {
//   box-sizing: border-box;
// }

@use "shared" as *;

// .mobile-section{
//   // height: 100vh;
//   // display: flex;
//   // flex-direction: column;
// }

.mobile_preview {
  // margin: 4rem 2rem;
  margin: 4rem 2rem 7rem;
  // width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.8rem;
  justify-content: flex-start;
  position: relative;
  // height: 100vh;
  // @media ((max-width: 850px) and (min-width:650px)) {
  //   height: 100vh;
  //   justify-content: center;
  // }
  // background-color: red;
}

.mobile_preview-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.4rem;
}

.mobile_preview-text {
  width: 100%;
  @include body-mono;
  padding-top: 3.2rem;
  // @media (min-width: 1920px) {
  //   padding-top: 0;
  // }
  // @media ((max-width: 850px) and (min-width:650px)) {
  //   padding-top: 0;
  //   width: 70%;
  // }
}
.mobile_preview-text-wrapper > h1 {
  z-index: 4;
  @include h1;
  z-index: 1;
  text-align: center;
  line-height: 100%;
  letter-spacing: -1.5px;
  font-size: 7rem;
  text-align: start;
  .highlight {
    z-index: 1;
    position: relative;
    // cursor: url("../../../../../icons/cursors/pointing.svg"), auto;
    transition: 0.3s;
  }
  // .highlight {
  //   // letter-spacing: -2.3px;
  // }
  .highlight::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 5px;
    width: 100%;
    border-bottom: 4px solid $black-color;
  }
}

.mobile_preview > h4 {
  @include h4;
  // text-align: center;
  font-size: 2.2rem;
  margin-top: 3.4vh;
  // width: 85%;
  align-self: flex-start;
  // @media ((max-width: 850px) and (min-width:650px)) {
  //   font-size: 2.8rem;
  // }
}

.mobile_preview-buttons-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
  width: 100%;
}
.mobile_preview-buttons-wrapper > button {
  @include button;
  border: 1px solid $black-color;
  gap: 20px;
  width: 100%;
  padding: 20px 18px 20px 20px;
  @media (max-width: 320px) {
    padding: 15px 18px;
  }
}

.mobile_preview-buttons-wrapper > button:hover {
  background-color: $black-color;
  color: $white-color;
  .mobile_preview-button-icon {
    filter: invert(100%) sepia(0%) saturate(7456%) hue-rotate(13deg)
      brightness(104%) contrast(98%);
  }
}

@media ((max-width: 320px)) {
  .mobile_preview-text-wrapper > h1 .highlight::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 3px;
    width: 100%;
    border-bottom: 3px solid #000000;
  }
}

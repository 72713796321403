@use 'shared' as *;

.survey__title {
    @include h5;
    text-transform: none;
}

.survey__select-label {
    @include body-mono;
    margin-top: 30px;
}

.survey__select {
    margin-top: 10px;
}

.survey__radio-label {
    @include body-mono;
    margin-top: 20px;
}

.survey__radio {
    margin-top: 20px;
}